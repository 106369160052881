import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import SeriesManager from './components/SeriesManager';
import HistoricalSeries from './components/HistoricalSeries';
import SeriesDetails from './components/SeriesDetails';
import GoalsTracker from './components/GoalsTracker';
import Investors from './components/Investors';
import InvestorDetails from './components/InvestorDetails';
import Login from './components/Login';
import Navbar from './components/Navbar'; // Importer le composant Navbar
import Stats from './components/Stats'; // Importer le composant Stats
import Logs from './components/Logs'; // Importer le composant Logs
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

function PrivateRoute({ element: Component, requiredRole, ...rest }) {
    const isAuthenticated = localStorage.getItem('isAuthenticated'); 
    const userRole = localStorage.getItem('role'); 

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    // Si un rôle est requis et que l'utilisateur n'a pas ce rôle
    // mais que l'utilisateur est admin, il est autorisé à accéder.
    if (requiredRole && userRole !== requiredRole && userRole !== 'admin') { 
        return <Navigate to="/" />; 
    }

    return <Component {...rest} />;
}

function App() {
    return (
        <Router>
            <div className="app">
                <header className="app-header">
                    VGerm1 Consulting
                </header>
                <Navbar />  {/* Inclure le Navbar ici */}
                <main className="app-main">
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        
                        {/* Routes accessibles uniquement aux administrateurs */}
                        <Route path="/series" element={<PrivateRoute element={SeriesManager} requiredRole="admin" />} />
                        <Route path="/historical" element={<PrivateRoute element={HistoricalSeries} requiredRole="admin" />} />
                        <Route path="/series/:id" element={<PrivateRoute element={SeriesDetails} requiredRole="admin" />} />
                        <Route path="/goals" element={<PrivateRoute element={GoalsTracker} requiredRole="admin" />} />
                        
                        {/* Routes accessibles aux administrateurs et aux investisseurs */}
                        <Route 
                            path="/investors" 
                            element={<PrivateRoute element={Investors} requiredRole="admin" />} 
                        />
                        <Route 
                            path="/investors/:id" 
                            element={<PrivateRoute element={InvestorDetails} requiredRole="investor" />} 
                        />

                        {/* Routes pour Stats et Logs */}
                        <Route path="/stats" element={<PrivateRoute element={Stats} requiredRole="admin" />} />
                        <Route path="/logs" element={<PrivateRoute element={Logs} requiredRole="admin" />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
