import React, { useState, useEffect } from 'react';
import './Stats.css';

const Stats = () => {
    const [stats, setStats] = useState({
        total_matches: 0,
        wins: 0,
        losses: 0
    });
    const [statsByType, setStatsByType] = useState([]);
    const [statsByTeam, setStatsByTeam] = useState([]);

    useEffect(() => {
        fetch('https://my-investor-app-9622243531b6.herokuapp.com/api/stats')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Stats data received:', data);
                setStats({
                    total_matches: parseInt(data.total_matches, 10),
                    wins: parseInt(data.wins, 10),
                    losses: parseInt(data.losses, 10)
                });
            })
            .catch(error => console.error('Error:', error));

        fetch('https://my-investor-app-9622243531b6.herokuapp.com/api/stats/by-type')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Stats by type data received:', data);
                setStatsByType(data);
            })
            .catch(error => console.error('Error:', error));

        fetch('https://my-investor-app-9622243531b6.herokuapp.com/api/stats/by-team')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Stats by team data received:', data);
                // Trier les données par nombre de matchs joués en ordre décroissant
                const sortedData = data.sort((a, b) => b.total_matches - a.total_matches);
                setStatsByTeam(sortedData);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const calculateWinPercentage = (wins, total) => {
        return total > 0 ? ((wins / total) * 100).toFixed(2) : '0.00';
    };

    return (
        <div className="stats-container">
            <h2>Statistiques des Paris</h2>
            <table className="stats-table">
                <thead>
                    <tr>
                        <th>Sport</th>
                        <th>Type</th>
                        <th>Matchs Joués</th>
                        <th>Paris Gagnés</th>
                        <th>Paris Perdus</th>
                        <th>% Gagnés</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total</td>
                        <td></td>
                        <td>{stats.total_matches}</td>
                        <td>{stats.wins}</td>
                        <td>{stats.losses}</td>
                        <td>{calculateWinPercentage(stats.wins, stats.total_matches)}%</td>
                    </tr>
                    {statsByType.map((stat, index) => (
                        <tr key={index}>
                            <td>{stat.sport}</td>
                            <td>{stat.type}</td>
                            <td>{stat.total_matches}</td>
                            <td>{stat.wins}</td>
                            <td>{stat.losses}</td>
                            <td>{calculateWinPercentage(stat.wins, stat.total_matches)}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h2>Statistiques par Équipe</h2>
            <table className="stats-table">
                <thead>
                    <tr>
                        <th>Sport</th>
                        <th>Équipe</th>
                        <th>Matchs Joués</th>
                        <th>Paris Gagnés</th>
                        <th>Paris Perdus</th>
                        <th>% Gagnés</th>
                    </tr>
                </thead>
                <tbody>
                    {statsByTeam.map((stat, index) => (
                        <tr key={index}>
                            <td>{stat.sport}</td>
                            <td>{stat.team_name}</td>
                            <td>{stat.total_matches}</td>
                            <td>{stat.wins}</td>
                            <td>{stat.losses}</td>
                            <td>{calculateWinPercentage(stat.wins, stat.total_matches)}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Stats;
